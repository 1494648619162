import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  useEffect(() => {
    const pacman = document.querySelector('.pacman');
    const dots = document.querySelectorAll('.dot');
    let animationFrameId;
    const eatenDots = new Set();

    const checkCollision = () => {
      const pacmanRect = pacman.getBoundingClientRect();
      const containerRect = document.querySelector('.pacman-container').getBoundingClientRect();
      const pacmanRelativeLeft = pacmanRect.left - containerRect.left;
      
      // Check if pacman has completed its run
      if (pacmanRelativeLeft >= containerRect.width) {
        cancelAnimationFrame(animationFrameId);
        return;
      }

      dots.forEach((dot, index) => {
        if (!eatenDots.has(index)) {
          const dotRect = dot.getBoundingClientRect();
          const dotRelativeLeft = dotRect.left - containerRect.left;
          
          // Add a small offset to make sure we catch the collision
          if (pacmanRelativeLeft + pacmanRect.width/2 >= dotRelativeLeft) {
            dot.style.opacity = '0';
            eatenDots.add(index);
          }
        }
      });

      animationFrameId = requestAnimationFrame(checkCollision);
    };

    animationFrameId = requestAnimationFrame(checkCollision);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, []);

  return (
    <div className="App">
      <Container fluid className="min-vh-100 d-flex align-items-center justify-content-center">
        <Row>
          <Col className="text-center logo-container">
            <img 
              src="assets/img/eatfootball-logo-pacman.png" 
              alt="EatFootball Pacman Logo" 
              className="mb-2"
            />
            <div className="mt-2">
              <img 
                src="assets/img/eatfootball-logo-text-lrg.png" 
                alt="EatFootball Full Logo" 
                className="mt-2"
              />
            </div>
            <div className="pacman-container mt-3">
              <div className="pacman">
                <div></div>
                <div></div>
                <div className="pacman-eye"></div>
              </div>
              <div className="dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
              <div className="player-text">EATING FOOTY NONSENSE SINCE 2006</div>
            </div>
            <div className="powered-by">
              <p className="powered-text">🌿 Powered By</p>
              <a href="ad.html?ad=ef-action-lada&hidebgimg=true">
                <img 
                  src="assets/img/ef-sponsor.png" 
                  alt="EatFootball Sponsor" 
                  className="sponsor-logo"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
